<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ sepcData.StockPropertyNo }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >SEMI-EXPENDABLE PROPERTY CARD</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(sepcData.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Stock No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ sepcData.StockPropertyNo }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Item Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ sepcData.ItemName }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-subheader class="font-weight-bold">Item Description:</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="px-4">
              {{ sepcData.ItemDescription }}
            </v-col>
          </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                :headers="headers"
                :items="itemList"
                class="elevation-3 mt-4"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >Acceptance and Issuances</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(sepcData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.CODE`]="{ item }">
                  <span class="text-no-wrap" v-if="item.IARID && !item.ICSID && !item.RSID">{{ item.IARCODE }}</span>
                  <span class="text-no-wrap" v-else-if="item.ICSID && !item.RSID">{{ item.ICSCODE }}</span>
                  <span class="text-no-wrap" v-else-if="item.RSID">{{ item.RSCODE }}</span>
                </template>
                <template v-slot:[`item.Qty_receipt`]="{ item }">
                  <span class="text-no-wrap" v-if="(item.IARID && !item.ICSID) || item.RSID">{{ formatQty(item.Qty)}}</span>
                  <span class="text-no-wrap" v-else-if="item.MRTypeID == 2 || item.MRTypeID == 3">{{ formatQty(item.Qty)}}</span>
                </template>
                <template v-slot:[`item.Qty_itd`]="{ item }">
                  <span class="text-no-wrap" v-if="item.MRTypeID == 2 || item.MRTypeID == 3"></span>
                  <span class="text-no-wrap" v-else-if="item.ICSID && !item.RSID">{{ formatQty(item.Qty)}}</span>
                </template>
                <template v-slot:[`item.receiver`]="{ item }">
                  <span class="text-no-wrap" v-if="item.receiver">{{ item.office}} - {{ item.receiver}}</span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                  <span class="text-no-wrap">{{ formatQty(item.Balance) || 0}}</span>
                </template>
                <template v-slot:[`item.Balance_amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.Balance_amount)}}</span>
                </template>
                <template v-slot:[`item.Remarks`]="{ item }">
                <span v-if="item.RenewItemID && !item.RSID">Renewed ({{item.RENEWCODE}})</span>
                <span v-else-if="item.TransferItemID && !item.RSID">Transferred ({{item.TRANSFERCODE}})</span>
                <span v-else-if="item.RSID">Returned ({{item.ICSCODE}}) - {{item.ReturnTypeDescription}}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  props: {
    sepcData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    link: "",
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "DateCreated",
      },
      {
        text: "Reference/ICS No.",
        align: "start",
        sortable: false,
        value: "CODE",
      },
      {
        text: "Qty (Receipt)",
        align: "center",
        sortable: false,
        value: "Qty_receipt",
      },
      {
        text: "Qty (I/T/D)",
        align: "center",
        sortable: false,
        value: "Qty_itd",
      },
      {
        text: "Office/User",
        value: "receiver",
        align: "center",
        sortable: false,
      },
      {
        text: "Qty (Balance)",
        value: "Balance",
        align: "center",
        sortable: false,
      },
      {
        text: "Amount (Balance)",
        value: "Balance_amount",
        align: "end",
        sortable: false,
      },
      {
        text: "Remarks",
        value: "Remarks",
        align: "center",
        sortable: false,
      },
      // { text: "Action", value: "Action", align: "center", sortable: false },
    ],
    itemList: [],
    submitbtn: false,
    dialogConfirmSubmit: false,
    dialogUnpublish: false,
    isLoading: false,
    risData: [],
    editData: [],
    expandedItemList: [],
    showRIS: false,
  }),
  watch: {
    sepcData: {
      handler(data) {
        if (data.ItemID || data.ICSItemID) {
          if(!data.IARItemID && data.ICSItemID){
          this.getSEPCListUploaded();
          }
          else{
          this.getSEPCList();
          }
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    print() {
      let link = this.apiUrl + "/sepc/report/" + this.sepcData.IARItemID + "/" + this.sepcData.StockPropertyNo;
      window.open(link);
    },
    closeDialog() {
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewSEPC", false);
    },
    getSEPCList() {
      this.itemList = [];
      this.loading = true;
      let data = new FormData();
      data.append("IARItemID", this.sepcData.IARItemID);
      data.append("StockPropertyNo", this.sepcData.StockPropertyNo);
      this.axiosCall("/sepc/getAcceptanceAndIssuance", "POST", data).then((res) => {
        this.itemList = res.data.data;
        this.itemList.forEach(item => {
          if(item.mrlist){
          item.mrlist = item.mrlist.flatMap(item2 => {
              if (!item2.rslist) {
                  return item2
              }
  
              return [{...item2, rslist: []}, ...item2.rslist]
          })
          }
        });
        this.itemList = this.itemList.flatMap(item => {
            if (!item.mrlist) {
                return item
            }

            return [{...item, mrlist: []}, ...item.mrlist]
        })
        let balance = parseFloat(this.itemList[0].Qty);
        let balance_amount = parseFloat(this.itemList[0].UnitCost) * parseFloat(this.itemList[0].Qty);
        this.itemList[0].Balance = balance;
        this.itemList[0].Balance_amount = balance_amount;
        this.itemList.forEach(item => {
          if((item.RenewItemID == null && item.TransferItemID == null) || item.ReturnItemID != null){
          if(item.ICSID && !item.RSID){
            balance = balance - parseFloat(item.Qty)
            balance_amount = balance_amount - ((parseFloat(item.UnitCost) * parseFloat(item.Qty)));
          }
          }
          if(item.RSID){
            balance = balance + parseFloat(item.Qty)
            balance_amount = balance_amount + ((parseFloat(item.UnitCost) * parseFloat(item.Qty)));
          }
          item.Balance = balance;
          item.Balance_amount = balance_amount
        });
        this.loading = false;
      });
    },
    getSEPCListUploaded() {
      this.itemList = [];
      this.loading = true;
      let data = new FormData();
      data.append("ICSItemID", this.sepcData.ICSItemID);
      this.axiosCall("/sepc/getAcceptanceAndIssuanceUploaded", "POST", data).then((res) => {
        this.itemList = res.data.data;
        this.itemList.forEach(item => {
          if(item.mrlist){
          item.mrlist = item.mrlist.flatMap(item2 => {
              if (!item2.rslist) {
                  return item2
              }
  
              return [{...item2, rslist: []}, ...item2.rslist]
          })
          }
        });
        this.itemList = this.itemList.flatMap(item => {
            if (!item.mrlist) {
                return item
            }

            return [{...item, mrlist: []}, ...item.mrlist]
        })
        let balance = parseFloat(this.itemList[0].Qty);
        let balance_amount = parseFloat(this.itemList[0].UnitCost) * parseFloat(this.itemList[0].Qty);
        this.itemList[0].Balance = balance;
        this.itemList[0].Balance_amount = balance_amount;
        this.itemList.forEach(item => {
          if((item.RenewItemID == null && item.TransferItemID == null && item.TopMostParentID) || item.ReturnItemID != null){
          if(item.ICSID && !item.RSID){
            balance = balance - parseFloat(item.Qty)
            balance_amount = balance_amount - ((parseFloat(item.UnitCost) * parseFloat(item.Qty)));
          }
          }
          if(item.RSID){
            balance = balance + parseFloat(item.Qty)
            balance_amount = balance_amount + ((parseFloat(item.UnitCost) * parseFloat(item.Qty)));
          }
          item.Balance = balance;
          item.Balance_amount = balance_amount
        });
        this.loading = false;
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>